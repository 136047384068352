// extracted by mini-css-extract-plugin
export var btn = "Robust-module--btn--ebe6c";
export var cards = "Robust-module--cards--614e5";
export var description = "Robust-module--description--cae83";
export var description2 = "Robust-module--description2--1d20d";
export var hire = "Robust-module--hire--0f66c";
export var hireImg = "Robust-module--hireImg--53151";
export var robustCustom = "Robust-module--robustCustom--eb702";
export var subTitle = "Robust-module--subTitle--66c37";
export var talent = "Robust-module--talent--8ba5b";
export var teamBtn = "Robust-module--teamBtn--6e575";
export var text = "Robust-module--text--d247f";
export var tick = "Robust-module--tick--a0ee8";