// extracted by mini-css-extract-plugin
export var cardBg = "CustomTechStacksMobile-module--cardBg--252db";
export var cards = "CustomTechStacksMobile-module--cards--d029c";
export var cardsContent = "CustomTechStacksMobile-module--cardsContent--c0006";
export var customTechMobile = "CustomTechStacksMobile-module--customTechMobile--3a6e1";
export var description = "CustomTechStacksMobile-module--description--7dc3e";
export var heading = "CustomTechStacksMobile-module--heading--171de";
export var iconssCard = "CustomTechStacksMobile-module--iconssCard--90a67";
export var nav = "CustomTechStacksMobile-module--nav--2542b";
export var navItem = "CustomTechStacksMobile-module--nav-item--c01be";
export var navbarBlock = "CustomTechStacksMobile-module--navbarBlock--4a0da";
export var tabData = "CustomTechStacksMobile-module--tabData--6ce00";
export var tech = "CustomTechStacksMobile-module--tech--1f03a";
export var techIcon = "CustomTechStacksMobile-module--techIcon--b1ac7";
export var techImg = "CustomTechStacksMobile-module--techImg--6b638";
export var technologyIcon = "CustomTechStacksMobile-module--technologyIcon--70ffd";