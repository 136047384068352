// extracted by mini-css-extract-plugin
export var btn = "WhyChoose-module--btn--2eda3";
export var cards = "WhyChoose-module--cards--f3cba";
export var customBtn = "WhyChoose-module--customBtn--1efdc";
export var description = "WhyChoose-module--description--dc9f7";
export var hireImg = "WhyChoose-module--hireImg--c3b57";
export var hireTechnical = "WhyChoose-module--hireTechnical--05978";
export var subTitle = "WhyChoose-module--subTitle--251ce";
export var talent = "WhyChoose-module--talent--8edc0";
export var teamBtn = "WhyChoose-module--teamBtn--132ec";
export var text = "WhyChoose-module--text--56c68";
export var tick = "WhyChoose-module--tick--1b015";
export var whyChooseWeb = "WhyChoose-module--whyChooseWeb--d6726";