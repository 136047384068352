// extracted by mini-css-extract-plugin
export var ServicesShape = "Shape-module--ServicesShape--6cdd1";
export var card = "Shape-module--card--29de3";
export var cardWrapper = "Shape-module--cardWrapper--02827";
export var customDesc = "Shape-module--customDesc--8efa6";
export var description = "Shape-module--description--706d4";
export var heading = "Shape-module--heading--68acb";
export var loader = "Shape-module--loader--2f397";
export var lodaMoreBtn = "Shape-module--lodaMoreBtn--4a628";
export var lodalessBtn = "Shape-module--lodalessBtn--009be";
export var noCodeDesc = "Shape-module--noCodeDesc--a6346";
export var noCodeHeading = "Shape-module--noCodeHeading--d2e96";
export var outsourceHead = "Shape-module--outsourceHead--aec87";
export var rescueHeading = "Shape-module--rescueHeading--5cdb0";
export var servicesRescue = "Shape-module--servicesRescue--7407c";
export var showlessdiv = "Shape-module--showlessdiv--29813";
export var spin = "Shape-module--spin--61b61";
export var trailBg = "Shape-module--trailBg--ddc8a";