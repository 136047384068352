import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Further.module.scss"

const Further = ({ strapiData }) => {
  return (
    <div className={`${styles.furtherServiceCustom}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards.map((e, i) => (
            <Col lg={6} md={6} xs={12} key={i}>
              <div className={styles.card}>
                <lottie-player
                  autoplay
                  loop
                  src={e?.image1[0]?.localFile?.publicURL}
                  style={{ height: "60px", width: "60px", margin: "0" }}
                />
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Further
