// extracted by mini-css-extract-plugin
export var cardBg = "CustomTechStacks-module--cardBg--5783e";
export var cards = "CustomTechStacks-module--cards--b41a1";
export var cardsContent = "CustomTechStacks-module--cardsContent--fce9d";
export var customTechStacksWeb = "CustomTechStacks-module--customTechStacksWeb--9320d";
export var description = "CustomTechStacks-module--description--685a4";
export var heading = "CustomTechStacks-module--heading--9cc73";
export var iconssCard = "CustomTechStacks-module--iconssCard--67a13";
export var nav = "CustomTechStacks-module--nav--5a278";
export var navItem = "CustomTechStacks-module--nav-item--cabdb";
export var navbarBlock = "CustomTechStacks-module--navbarBlock--7d6d8";
export var tabData = "CustomTechStacks-module--tabData--1865f";
export var tech = "CustomTechStacks-module--tech--a4380";
export var techIcon = "CustomTechStacks-module--techIcon--6d7a9";
export var techImg = "CustomTechStacks-module--techImg--b02c0";
export var technologyIcon = "CustomTechStacks-module--technologyIcon--b692d";