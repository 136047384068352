import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./CustomTechStacks.scss"
import * as styles from "./CustomTechStacks.module.scss"

const data = [
  {
    title: "Frontend",
    tech: [
      {
        name: "React.js",
        icon: "https://invozone-backend.s3.amazonaws.com/react_js_6c607ab45c.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_3e5d058b96.svg",
      },
      {
        name: "HTML",
        icon: "https://invozone-backend.s3.amazonaws.com/html_732cfbbb9e.svg",
      },
      {
        name: "CSS",
        icon: "https://invozone-backend.s3.amazonaws.com/css_75369c5202.svg",
      },
      {
        name: "Gatsby",
        icon: "https://invozone-backend.s3.amazonaws.com/gatsby_d516e7112e.svg",
      },
      {
        name: "Vue.js",
        icon: "https://invozone-backend.s3.amazonaws.com/vue_js_363d529824.svg",
      },
      {
        name: "Ember.js",
        icon: "https://invozone-backend.s3.amazonaws.com/ember_js_3a2d934135.svg",
      },
      {
        name: "Svelte",
        icon: "https://invozone-backend.s3.amazonaws.com/svelte_a33c5fb6a3.svg",
      },
      {
        name: "Backbone.js",
        icon: "https://invozone-backend.s3.amazonaws.com/backbone_js_e97e7a64e0.svg",
      },
      {
        name: "Polymer",
        icon: "https://invozone-backend.s3.amazonaws.com/polymer_4dab3cd6d6.svg",
      },
      {
        name: "Aurelia",
        icon: "https://invozone-backend.s3.amazonaws.com/aurelia_b3b17a2f2a.svg",
      },
      {
        name: "Meteor.js",
        icon: "https://invozone-backend.s3.amazonaws.com/meteor_js_54ff036ae1.svg",
      },
      {
        name: "Next.js",
        icon: "https://invozone-backend.s3.amazonaws.com/next_js_150a6a76ba.svg",
      },
      {
        name: "Semantic-UI",
        icon: "https://invozone-backend.s3.amazonaws.com/semantic_ui_18a5764044.svg",
      },
      {
        name: "Nuxt",
        icon: "https://invozone-backend.s3.amazonaws.com/nuxt_43a7164c6f.svg",
      },
      {
        name: "Alpine.js",
        icon: "https://invozone-backend.s3.amazonaws.com/Alpine_js_3a3dc8e1a2.svg",
      },
    ],
  },
  {
    title: "Backend",
    tech: [
      {
        name: "Node.js",
        icon: "https://invozone-backend.s3.amazonaws.com/node_js_1798160aeb.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.amazonaws.com/elixir_609f4e8bd5.svg",
      },
      {
        name: ".Net",
        icon: "https://invozone-backend.s3.amazonaws.com/dot_net_core_07494615c9.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.amazonaws.com/python_87951806aa.svg",
      },
      {
        name: "Ruby on Rails",
        icon: "https://invozone-backend.s3.amazonaws.com/ruby_on_rails_78d29dc8bc.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.amazonaws.com/java_8c6c53d9d5.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.amazonaws.com/php_df08866891.svg",
      },
      {
        name: "Django",
        icon: "https://invozone-backend.s3.amazonaws.com/django_bc7d32b851.svg",
      },
      {
        name: "GO",
        icon: "https://invozone-backend.s3.amazonaws.com/golang_af33d97d05.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.amazonaws.com/C_01bd0dbef1.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.amazonaws.com/kotlin_a297c85d16.svg",
      },
      {
        name: "Scala",
        icon: "https://invozone-backend.s3.amazonaws.com/scala_2e03563676.svg",
      },
      {
        name: "Rust",
        icon: "https://invozone-backend.s3.amazonaws.com/rust_950f689b8d.svg",
      },
    ],
  },
  {
    title: "Libraries",
    tech: [
      {
        name: "jQuery",
        icon: "https://invozone-backend.s3.amazonaws.com/jquery_ec723c935f.svg",
      },
      {
        name: "React",
        icon: "https://invozone-backend.s3.amazonaws.com/react_9aef019c87.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_3e5d058b96.svg",
      },
      {
        name: "Vue.js",
        icon: "https://invozone-backend.s3.amazonaws.com/vue_js_1fc7d4170d.svg",
      },
      {
        name: "Vue.x",
        icon: "https://invozone-backend.s3.amazonaws.com/vue_x_02ab70bb86.svg",
      },
      {
        name: "Rxjs",
        icon: "https://invozone-backend.s3.amazonaws.com/rx_js_c25c3dc4c7.svg",
      },
      {
        name: "Bootstrap",
        icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_bb20df2e8a.svg",
      },
      {
        name: "Backbone.js",
        icon: "https://invozone-backend.s3.amazonaws.com/backbone_js_e97e7a64e0.svg",
      },
      {
        name: "Ember.js",
        icon: "https://invozone-backend.s3.amazonaws.com/ember_js_c658b7b960.svg",
      },
      {
        name: "D3.js",
        icon: "https://invozone-backend.s3.amazonaws.com/d3_js_9a1ec60030.svg",
      },
      {
        name: "Redux",
        icon: "https://invozone-backend.s3.amazonaws.com/redux_df2c6c2f02.svg",
      },
      {
        name: "Lodash",
        icon: "https://invozone-backend.s3.amazonaws.com/lodash_a92ed646c4.svg",
      },
      {
        name: "Styled component",
        icon: "https://invozone-backend.s3.amazonaws.com/styled_component_4fab00ca49.svg",
      },
      {
        name: "Emotion",
        icon: "https://invozone-backend.s3.amazonaws.com/emotion_2cc14a0dc9.svg",
      },
      {
        name: "Graph QL",
        icon: "https://invozone-backend.s3.amazonaws.com/graphql_64900f0cb9.svg",
      },
      {
        name: "Ant design",
        icon: "https://invozone-backend.s3.amazonaws.com/ant_design_45399a05bc.svg",
      },
      {
        name: "Inertia.js",
        icon: "https://invozone-backend.s3.amazonaws.com/inertia_js_f31053abeb.svg",
      },
      {
        name: "Mobx",
        icon: "https://invozone-backend.s3.amazonaws.com/mobx_3aab383efb.svg",
      },
      {
        name: "Web3",
        icon: "https://invozone-backend.s3.amazonaws.com/web3_js_5343b9f853.svg",
      },
      {
        name: "WebGL",
        icon: "https://invozone-backend.s3.amazonaws.com/Web_GL_2e8ab586a5.svg",
      },
      {
        name: "Core UI",
        icon: "https://invozone-backend.s3.amazonaws.com/core_ui_21f2c32bb7.svg",
      },
      {
        name: "Tailwind",
        icon: "https://invozone-backend.s3.amazonaws.com/tailwind_6e2075c057.svg",
      },
      {
        name: "Material UI",
        icon: "https://invozone-backend.s3.amazonaws.com/material_ui_ac94034f00.svg",
      },
      {
        name: "Flow.js",
        icon: "https://invozone-backend.s3.amazonaws.com/flow_js_69264dac66.svg",
      },
    ],
  },
]

const CustomTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="customTabStacks">
      <div className={styles.customTechStacksWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`customTabStacks`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockcustom">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default CustomTechStacks
