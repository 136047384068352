// extracted by mini-css-extract-plugin
export var arrowPics1 = "Stages-module--arrowPics1--851d0";
export var arrowPics2 = "Stages-module--arrowPics2--21d47";
export var arrowPics3 = "Stages-module--arrowPics3--5cd23";
export var arrowPics4 = "Stages-module--arrowPics4--959f3";
export var arrowPics5 = "Stages-module--arrowPics5--3ff19";
export var bgImage = "Stages-module--bgImage--03f2e";
export var borderStock = "Stages-module--borderStock--f2119";
export var dottedLine1 = "Stages-module--dottedLine1--17ec8";
export var giffBg = "Stages-module--giffBg--46288";
export var giffBg2 = "Stages-module--giffBg2--2dd8a";
export var giffBg3 = "Stages-module--giffBg3--dffa9";
export var giffBg4 = "Stages-module--giffBg4--1915a";
export var giffBg5 = "Stages-module--giffBg5--816d6";
export var giffBg6 = "Stages-module--giffBg6--853f6";
export var imageGiffs = "Stages-module--imageGiffs--17043";
export var lottieImages1 = "Stages-module--lottieImages1--be0c4";
export var lottieImages2 = "Stages-module--lottieImages2--fea66";
export var lottieImages3 = "Stages-module--lottieImages3--45b2f";
export var lottieImages4 = "Stages-module--lottieImages4--8690e";
export var lottieImages5 = "Stages-module--lottieImages5--22ea8";
export var lottieImages6 = "Stages-module--lottieImages6--418b6";
export var mainDottedLeft1 = "Stages-module--mainDottedLeft1--51455";
export var mainDottedLeft2 = "Stages-module--mainDottedLeft2--451a7";
export var mainDottedLeft3 = "Stages-module--mainDottedLeft3--840dd";
export var mainDottedRight1 = "Stages-module--mainDottedRight1--2bf68";
export var mainDottedRight2 = "Stages-module--mainDottedRight2--93109";
export var mainDottedRight3 = "Stages-module--mainDottedRight3--d6e66";
export var overlay = "Stages-module--overlay--80acc";
export var processData = "Stages-module--processData--cd9bb";
export var processData2 = "Stages-module--processData2--52870";
export var processData3 = "Stages-module--processData3--ef471";
export var processDes = "Stages-module--processDes--8b509";
export var processDetails = "Stages-module--processDetails--be2f5";
export var processes = "Stages-module--processes--72d09";
export var rightProcess = "Stages-module--rightProcess--f67b5";
export var rightProcessData = "Stages-module--rightProcessData--17b65";
export var rightProcessData2 = "Stages-module--rightProcessData2--b8e50";
export var rightProcessData3 = "Stages-module--rightProcessData3--301cb";
export var rightProcessDes = "Stages-module--rightProcessDes--178b9";
export var stagegyDescription = "Stages-module--stagegyDescription--c0876";
export var stagesCustomProcess = "Stages-module--stagesCustomProcess--9af7c";
export var strategyHeading = "Stages-module--strategyHeading--f9619";