import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import MainLayout from "../../layouts/MainLayout"
import Need from "../../components/web-application/Need"
import Banner from "../../components/custom/Banner"
import PackedIndustry from "../../components/web-application/PackedIndustry"
import Opportunity from "../../components/web-application/Opportunity"
import Clients from "../../components/web-application/ClientsWeb"
import Projects from "../../components/web-application/Projects"
import ProjectsMobile from "../../components/web-application/ProjectsMobile"
import Shape from "../../components/web-application/Shape"
import Robust from "../../components/custom/Robust"
import CustomTechStacks from "../../components/custom/CustomTechStacks"
import Further from "../../components/custom/Further"
import Stages from "../../components/custom/Stages"
import WhyChoose from "../../components/web-application/WhyChoose"
import MobileStages from "../../components/custom/MobileStages"
import CustomTechStacksMobile from "../../components/custom/CustomTechStacksMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const webdevelopment = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const servicesCustom = data?.strapiPage?.sections[1]
  const customapp = data?.strapiPage?.sections[2]
  const customIndustry = data?.strapiPage?.sections[3]
  const tech = data?.strapiPage?.sections[4]
  const robust = data?.strapiPage?.sections[5]
  const further = data?.strapiPage?.sections[6]
  const stages = data?.strapiPage?.sections[7]
  const why = data?.strapiPage?.sections[8]
  const opportunityCustom = data?.strapiPage?.sections[9]
  const clietny = data?.strapiPage?.sections[10]
  const projects = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[12]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <Shape strapiData={servicesCustom} outsourcing={true} />
      <Need strapiData={customapp} custom={true} />
      <PackedIndustry strapiData={customIndustry} cmsIndustries={true} />
      {!isMobile && !isTablet ? (
        <CustomTechStacks strapiData={tech} />
      ) : (
        <CustomTechStacksMobile strapiData={tech} />
      )}
      <Robust strapiData={robust} />
      <Further strapiData={further} />
      {!isMobile && !isTablet ? (
        <Stages strapiData={stages} />
      ) : (
        <MobileStages strapiData={stages} />
      )}
      <WhyChoose strapiData={why} outsourcingimage={true} />
      <Opportunity strapiData={opportunityCustom} />
      <Clients strapiData={clietny} />
      {!isMobile && !isTablet ? (
        <Projects strapiData={projects} />
      ) : (
        <ProjectsMobile strapiData={projects} />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "custom-web-application" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default webdevelopment
